@import "variables";

.btn {
  margin: 0;
  padding: 1.5rem 4.5rem;
  font-size: $base-font-size;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  border: none;
  outline: none;
  cursor: pointer;
  //transition: all 0.3s ease-in-out;

  &.btn-primary,
  &.btn-next,
  &.btn-theme {
    color: $white-color;
    background: $third-color;
  }

  &.btn-secondary,
  &.btn-prev {
    background: rgba($grey-text-color, 0.2);
    color: $dark-text-color;
  }

  &.btn-success {
    font-size: $small-base-font-size;
    padding: 1.5rem 3.5rem;
  }

  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }

  &:hover {
    background-color: $main-color;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}
