@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

@import "./feather";

// basic colors:
$white-color: #fff;
$black-color: #000;
$yellow-color: #ffff00;
$main-color: #49525b;
$secondary-color: #00008f;
$third-color: #ff585f;
$border-color: #d2d8dd;
$dark-color: #222;
$gray-color: #6c757d;
$light-gray-color: #ccc;
$lighter-gray-color: #f4f4f4;
$error-block-color: #bc5a45;
$form-focus-border: #434bdf;
$tab-title-bottom-border: #ff585f;
$button-hover-color: #d80075;
$dark-text-color: #050708;
$grey-text-color: #666;
$dark-green: #334048;

// login menu colors:
$login-menu-color-primary: #4886ff;
$login-menu-dark-background: #242e3e;
$login-menu-text-color: #535763;
$login-menu-icon-color: #242e3ecc;

$axa-form-primary-color: #434bdf;

// datepicker colors:
$selected-date-background: $dark-green;

// breakpoints:
$breakpoint-xsmall: 480px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1400px;
$breakpoint-full: 1600px;

// font-sizes:
$xxs-font-size: 1rem;
$xs-font-size: 1.1rem;
$small-font-size: 1.2rem;
$small-base-font-size: 1.3rem;
$base-font-size: 1.4rem;
$base-m-font-size: 1.6rem;
$base-ml-font-size: 1.7rem;
$m-font-size: 1.8rem;
$ml-font-size: 2rem;
$l-font-size: 2.2rem;
$x-font-size: 2.4rem;
$xl-font-size: 2.6rem;
$xll-font-size: 2.8rem;
$xxl-font-size: 3.2rem;
$xxxl-font-size: 3.5rem;
$xxxxl-font-size: 4.5rem;

$form-font: 'Work Sans', sans-serif;
$form-font: 'Ubuntu', sans-serif;
@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Bold.eot');
  src: url('./fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Gotham-Bold.svg#Gotham-Bold') format('svg'),
  url('./fonts/Gotham-Bold.ttf') format('truetype'),
  url('./fonts/Gotham-Bold.woff') format('woff'),
  url('./fonts/Gotham-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('./fonts/Gotham-Medium.eot');
  src: url('./fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Gotham-Medium.svg#Gotham-Medium') format('svg'),
  url('./fonts/Gotham-Medium.ttf') format('truetype'),
  url('./fonts/Gotham-Medium.woff') format('woff'),
  url('./fonts/Gotham-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/Gotham-Light.eot');
  src: url('./fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Gotham-Light.svg#Gotham-Light') format('svg'),
  url('./fonts/Gotham-Light.ttf') format('truetype'),
  url('./fonts/Gotham-Light.woff') format('woff'),
  url('./fonts/Gotham-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
