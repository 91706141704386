@import "../../../../assets/styles/variables";

.header {
  margin: 0 0 2rem 0;
  padding: 0;
  font-weight: 700;
  font-size: 2rem;
  text-align: left;
}

.StripeElement {
  display: flex;
  align-items: center;
  height: 4.4rem;
  padding: .35rem 1.5rem;
  color: $main-color;
  background-color: white;
  border: 0.1rem solid $border-color;
  width: 100%;

  &.StripeElement--focus {
    //border-color: ;
  }

  &.StripeElement--invalid {
    border-color: $third-color;
  }

  &> div {
    flex: 1;
  }
}
