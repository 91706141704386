@import "variables";

.basic-single {
  width: 100%;

  .select__control {
    display: flex;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-image: url("../../assets/images/arrow_down_icon.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 1.5rem);
    -webkit-appearance: none;
    border: thin solid #d2d8dd;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    float: left;
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: normal;
    min-height: 4.4rem;
    outline: none;
    padding: .375rem 1.5rem;
    position: relative;
    text-align: left !important;
    transition: all 0.2s ease-in-out;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    color: #6c757d;
    border-radius: 0;

    &:focus {
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
      }
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &::-moz-focus-inner {
      border: 0;
    }

    &:hover {
      border-color: #d2d8dd;
    }

    .select__input {
      input {
        height: initial!important;
      }
    }

    .select__indicators {
      display: none;
    }

    .select__value-container {
      padding: 0;

      .select__single-value {
        color: inherit;
      }
    }

    &.select__control--is-focused {
      border-color: #d2d8dd;
      box-shadow: none;
    }
  }

  .select__menu {
    z-index: 9999;
    margin: 0;
    background-color: #fff;
    font-size: 16px;
    color: #6c757d;
    font-family: "Ubuntu", sans-serif;
    border-radius: 0;

    .select__menu-list {
      max-height: 250px;

      .select__option {
        padding: 2px 12px;
      }
    }
  }
}