@import "./variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
button,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}


button {
  background: transparent;
  outline: none;
}

.sr-only {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  padding: 0;
  margin: -0.1rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

::-moz-focus-inner {
  outline:none;
  border:0;
  -moz-outline-style: none;
}

.mb-0 {
  margin-bottom: 0!important;
}

.mb-10 {
  margin-bottom: 1rem!important;
}

.d-block {
  display: block!important;
}

.radio-wrapper {
  display: flex;

  .radio-input {
    display: none;

    &[type=radio]:checked + .radio-label {
      color: #fff;
      background-color: #ff585f
    }

    &:disabled + .radio-label {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .radio-label {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E0F7EF;
    color: #35E4A1;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    width: 6rem;
    margin: 0 1rem 1rem 0;

    &:hover {
      cursor: pointer;
    }
  }

  .radio-checkmark {
    display: none;
  }
}

.time-slots-container {
  display: flex;
  flex-wrap: wrap;
}

.input-group > div {
  width: 100%;
}

.slots-container {
  display: flex;
  flex-direction: column;

  .slots-label {
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 700;
    margin: 1rem 0;
    color: $grey-text-color;
    font-size: $base-font-size;
    min-height: 1.7rem;
  }

  .slots {
    display: flex;
    flex-wrap: wrap;
  }
}

form {
  label[for="consultationDate"].form-label {
    position: relative;
    top: 40px;
    z-index: 98;
  }
}

.login-user-menu {
  .button-container {
    .btn {
      width: 100%;
    }
  }
}

.registration-user-menu {
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}
